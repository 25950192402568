const $ = window.$

export let mondialMap = {
    mondialMap : () => {
        $(document).ready(function() {
            $("#Zone_Widget").MR_ParcelShopPicker({     
            Target: "#Target_Widget"                                // Selecteur JQuery de l'élément dans lequel sera renvoyé l'ID du Point Relais sélectionné (généralement un champ input hidden)  
            // ,TargetDisplay: "#TargetDisplay_Widget"                // Selecteur JQuery de l'élément dans lequel sera renvoyé l'ID du Point Relais sélectionné (secondaire pour affichage)  
            // ,TargetDisplayInfoPR: "#TargetDisplayInfoPR_Widget"    // Selecteur JQuery de l'élément dans lequel seront renvoyé les coordonnées complètes de la selection de l'utilisateur  
            ,Brand: "BDTEST  "                                     // Le code client Mondial Relay  
                                                                    // Lorsqu'il est indiqué "BDTEST  ", un message d'avertissement apprait pour avertir que ce sont les paramètres de test qui sont utilisés  
            ,Country: "FR"                                         // Code ISO 2 lettres du pays utilisé pour la recherche  
            ,AllowedCountries: "FR"                                 // Liste des pays selectionnable par l'utilisateur pour la recherche (codes ISO 2 lettres séparés par des virgules)  
            ,PostCode: "16190"                                     // Code postal pour lancer une recherche par défaut  
            //,EnableGeolocalisatedSearch: ""                       // Active ou non la possibilité d'effectuer la recherche sur la position courante lorsque le navigateur de l'utilisateur supporte cette fonction (demande au navigateur)  
            ,ColLivMod: "24R"                                      // Permet de filtrer les Points Relais selon le mode de livraison utilisé (Standard [24R], XL [24L], XXL [24X], Drive [DRI])  
            //,Weight: ""                                           // Permet de filtrer les Points Relais selon le Poids (en grammes) du colis à livrer  
            ,NbResults: "6"                                        // Nombre de Point Relais à renvoyer  
            ,SearchDelay: "15"                                      // Permet de spécifier le nombre de jour entre la recherche et la dépose du colis dans notre réseau  
            ,SearchFar: "10"                                        // Permet de limiter la recherche des Points Relais à une distance maximum  
            // ,CSS: "1"                                             // Permet de spécifier que vous souhaitez utiliser votre propre feuille de style CSS lorsque vous lui donnez la valeur "0"  
            // ,MapScrollWheel: "true"                              // Active ou non le zoom on scroll sur la carte des résultats  
            // ,MapStreetView: "false"                               // Active ou non le mode Street View sur la carte des résultats (attention aux quotas imposés par Google)  
            ,ShowResultsOnMap: "true"                             // Active ou non l'affichage des résultats sur une carte  
            ,Reponsive: true
            // ,DisplayMapInfo: true  
            ,OnParcelShopSelected:                                 // Permet l'appel d'une fonction lors de la selection d'un Point Relais  
                function(data) {
                    $("#Target_Widget").change()
                }
            });  
        })
    }
}