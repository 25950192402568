import React from 'react';
import { withAuthorization } from '../Session';
import * as ROLES from '../../constants/roles';
import { compose } from 'recompose';

import axios from 'axios';

import {ToggleOff, ToggleOn} from 'react-bootstrap-icons';

const baseURL = (process.env.NODE_ENV ==='production') ? process.env.REACT_APP_BACKEND_URL_PROD : process.env.REACT_APP_BACKEND_URL_DEV

const axiosSQL = axios.create({
    baseURL : baseURL + '/admin'
})

const Spinner = () => (
    <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
    </div>
)

const today = new Date();
const INITIAL_STATE = {
    code: '',
    description: '',
    valid_from: today.toISOString().split('T')[0],
    valid_until: new Date(today.setMonth(today.getMonth() + 3)).toISOString().split('T')[0] ,
    amount: 0,
    valid_flag : true,
  };

class RedeemPage extends React.Component {
    constructor (props) {
        super (props)
        this.state = {
            redeemList : [],
            loading : true,
            message : "",
            ...INITIAL_STATE
        }
    }

    async componentDidMount () {
        try {
          const redeemList = await axiosSQL.get('/redeem/list')
          this.setState({
            redeemList : redeemList.data,
            loading : false
            })
        } catch(error) {
          this.setState({message : JSON.stringify(error)});
        }
      }
    
    onSubmit = event => {
        event.preventDefault();
        let redeemEntry = this.state;
        delete redeemEntry['redeemList']
        delete redeemEntry['message']
        delete redeemEntry['loading']
        redeemEntry = {
            createdbyId : this.props.authUser.uid,
            createdbyName : this.props.authUser.displayName,
            createdDate : new Date().toISOString().split('T')[0],
            ...redeemEntry
        }
        axiosSQL
            .post('/redeem/addcode/', redeemEntry)
            .then(res => {
                axiosSQL
                    .get('/redeem/list')
                    .then(res => {
                        this.setState({
                            redeemList : res.data,
                            loading : false
                            })      
                    })
                    .catch(error => {this.setState({message : JSON.stringify(error)})});
                  })
            .catch(err => {this.console('there was an error adding the redeem code', err)})
    }

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
    
    toggleValidity = (event) => {
        if (event.target.id) {
            let temp = this.state.redeemList
            let temp_index = temp.findIndex(item => item.id === parseInt(event.target.id))
            temp[temp_index].valid_flag = !this.state.redeemList[temp_index].valid_flag
            axiosSQL.post(`/redeem/validity/${event.target.id}/${temp[temp_index].valid_flag}`)
                .then(this.setState({redeemList : temp}))
                .catch(err => {console.log('there is an error updating the flag', err)})
            } else {
            console.log('click next door')
        }
    }

    render () {
        const {
            code,
            description,
            valid_from,
            valid_until,
            amount,
            redeemList
          } = this.state;
        return(
            <div className="container">
                <h1>List of current discounts</h1>
                {this.state.loading && <strong style={{"color" : "lightgray"}}>loading <Spinner animation="border" /> </strong>}
                <div className="container overflow-auto">
                    <form onSubmit={this.onSubmit}>
                        <button className="btn btn-info mt-5 mb-2" type="submit">Create New Code</button>
                        <div className="form-row">
                            <div className="col-md-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    name="code"
                                    value={code}
                                    placeholder = "Enter your code"
                                    onChange = {this.onChange}
                                />
                            </div>
                            <div className="col-md-9">
                                <input
                                        type="text"
                                        className="form-control"
                                        name="description"
                                        value={description}
                                        placeholder = "Enter a brief description"
                                        onChange = {this.onChange}
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-3">
                                <label htmlFor="valid_from"><small>valid from date</small></label>
                                <input
                                    type="date"
                                    className="form-control"
                                    name="valid_from"
                                    id = "valid_from"
                                    value={valid_from}
                                    onChange = {this.onChange}
                                />
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="valid_until"><small>valid until date</small></label>
                                <input
                                        type="date"
                                        className="form-control"
                                        name="valid_until"
                                        id = "valid_until"
                                        value={valid_until}
                                        onChange = {this.onChange}
                                />
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="amount"><small>Enter the discount in percent</small></label>
                                <input
                                        type="number"
                                        className="form-control"
                                        name="amount"
                                        id="amount"
                                        value={amount}
                                        placeholder = '10'
                                        onChange = {this.onChange}
                                />
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="valid_flag"><small>Choose if the discount is enabled</small></label>
                                <select className="form-control" id="valid_flag" onChange = {this.onChange} name="valid_flag">
                                    <option value="true">Valid</option>
                                    <option value="false">Disabled</option>
                                </select>
                            </div>
                        </div>
                    </form>
                    <hr/>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">id</th>
                                <th scope="col">name</th>
                                <th scope="col">description</th>
                                <th scope="col">valid from</th>                                
                                <th scope="col">valid until</th>
                                <th scope="col">created by</th>
                                <th scope="col">created at</th>
                                <th scope="col">toggle validity</th>
                            </tr>
                        </thead>
                        <tbody>
                                { redeemList.map (
                                    code => {
                                        return (
                                            <tr key={code.id} 
                                                className={code.valid_flag 
                                                    ? new Date(code.valid_until) > new Date() ? "" : "table-warning" 
                                                    : "table-secondary"}
                                            >
                                                <td>{code.id}</td>
                                                <td>{code.name}</td>
                                                <td>{code.description}</td>
                                                <td>{new Date(code.valid_from).toLocaleDateString()}</td>
                                                <td>{new Date(code.valid_until).toLocaleDateString()}</td>
                                                <td>{code.created_by_name}</td>
                                                <td>{new Date(code.created_date).toLocaleDateString()}</td>
                                                <td className="text-center">{code.valid_flag ? <ToggleOn id={code.id} size="25" onClick={this.toggleValidity}/> : <ToggleOff id={code.id} size="25" onClick={this.toggleValidity}/>}</td>
                                            </tr>
                                        )
                                    }
                                )
                                }
                        </tbody>
                    </table>
                </div>
            </div>

        )

    }
}


const condition = authUser => authUser && authUser.roles.includes(ROLES.ADMIN);

export default compose(
  withAuthorization(condition),
)(RedeemPage);