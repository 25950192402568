import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Navigation from '../Navigation';
import LandingPage from '../Landing';
import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import PasswordResetPage from '../PasswordReset';
import ShoppingPage from '../Shopping';
import AccountPage from '../Account';
import AdminPage from '../Admin';
import OrderPage from '../Orders';
import myOrderPage from '../myOrders';
import checkoutPage from '../Checkout'
import userPage from '../Admin/userAdmin'
import RedeemPage from '../Redeem'
import NonAvailable from '../Admin/na';

import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';

class App extends React.Component {
    render () {
        return (
            <Router>
                <div>
                    <Navigation />
                    <Route exact path={ROUTES.LANDING} component={LandingPage} />
                    <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
                    <Route path={ROUTES.SIGN_IN} component={SignInPage} />
                    <Route path={ROUTES.PASSWORD_RESET} component={PasswordResetPage} />
                    <Route path={ROUTES.SHOPPING} component={ShoppingPage} />
                    <Route path={ROUTES.ACCOUNT} component={AccountPage} />
                    <Route path={ROUTES.ADMIN} component={AdminPage} />
                    <Route path={ROUTES.MANAGE_ORDERS} component={OrderPage} />
                    <Route path={ROUTES.MY_ORDERS} component={myOrderPage} />
                    <Route path={ROUTES.CHECKOUT} component={checkoutPage} />
                    <Route path={ROUTES.USERS} component={userPage} />
                    <Route path={ROUTES.REDEEM} component={RedeemPage} />
                    <Route path={ROUTES.NOTAVAILABLE} component={NonAvailable} />
                </div>
            </Router>
        )
    }
}


export default withAuthentication(App);
