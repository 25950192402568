
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { compose } from 'recompose';

import { withAuthorization } from '../Session';
import { UserList, UserItem } from '../Users';
import * as ROLES from '../../constants/roles';
import * as ROUTES from '../../constants/routes';

const UserPage = () => (
    <div className="container">
        <Switch>
            <Route exact path={ROUTES.USERS_DETAIL} component={UserItem} />
            <Route exact path={ROUTES.USERS} component={UserList} />
        </Switch>
    </div>
)


const condition = authUser =>
  authUser && authUser.roles.includes(ROLES.ADMIN);

export default compose(
  withAuthorization(condition),
)(UserPage);