import { combineReducers } from 'redux';
import sessionReducer from './session';
import userReducer from './user';
import cartReducer from './cart';
 
const rootReducer = combineReducers({
  sessionState: sessionReducer,
  userState: userReducer,
  cartState: cartReducer,
});
 
export default rootReducer;