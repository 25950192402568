import React, {Component} from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import {ClipboardCheck} from 'react-bootstrap-icons';
import {SignInGoogle} from '../SignIn'
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';


const SignUpPage = () => (
  <div className="container">
    <SignUpForm />
    <hr/>
    <div className="container text-center">
      <p>Or use your social account</p>
      <div className="container text-center">
        <SignInGoogle />
      </div>
    </div>
  </div>
);

const INITIAL_STATE = {
  firstname: '',
  lastname: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  phoneNumber :'',
  error: null,
  roles : '[]'
};

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }
 
  onSubmit = event => {
    const { firstname, lastname, email, passwordOne, phoneNumber, roles} = this.state;
    
    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        // Create a user in your Firebase realtime database
        return this.props.firebase
          .user(authUser.user.uid)
          .set({
            firstname,
            lastname,
            email,
            roles,
            phoneNumber,
            displayName : [firstname, lastname].join(' ')
          })
          .then(() => {
            this.props.firebase.auth.currentUser.updateProfile({displayName : [firstname, lastname].join(' ')})
          })
      })
      .then(() => {
        return this.props.firebase.doSendEmailVerification();
      })
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.SHOPPING);
      })
      .catch(error => {
        this.setState({ error });
      });
  
    event.preventDefault();
  }
 
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };


  render() {
    const {
      firstname,
      lastname,
      email,
      passwordOne,
      passwordTwo,
      error,
      phoneNumber
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      firstname === '' ||
      lastname === '' ||
      phoneNumber === '';

    return (
      <div className="container">
          <div className="container text-center">
            <ClipboardCheck size="55" alt="" fill ="#a32222"/>
            <h1 className="h2 font-weight-normal">Register</h1>
          </div>
          <form className="form-signin" onSubmit={this.onSubmit}>
            <div className="row">
            <div className="col-sm-6">
            <label for="email">Email</label>
                <input
                name="email"
                className="form-control"
                value={email}
                onChange={this.onChange}
                type="text"
                placeholder="Email Address"
                autoComplete="email"
                />
            </div>
            <div className="col-sm-6">
            <label for="phoneNumber">Phone</label>
                <input
                name="phoneNumber"
                className="form-control"
                value={phoneNumber}
                onChange={this.onChange}
                type="phone"
                placeholder="Phone"
                autoComplete="phone"
                />
            </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
              <label for="firstname">First Name</label>
                <input
                  name="firstname"
                  className="form-control"
                  value={firstname}
                  onChange={this.onChange}
                  type="text"
                  placeholder="First Name"
                  autoComplete="given-name"
                />
              </div>
              <div className="col-sm-6">
              <label for="lastname">Last Name</label>
                <input
                  name="lastname"
                  className="form-control"
                  value={lastname}
                  onChange={this.onChange}
                  type="text"
                  placeholder="Last Name"
                  autoComplete="family-name"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <label for="passwordOne">Enter Password</label>
                <input
                name="passwordOne"
                className="form-control"
                value={passwordOne}
                onChange={this.onChange}
                type = "password"
                autoComplete="new-password"
                placeholder="Password"
                />
                <small className="form-text text-muted">
                    Please use more than 6 characters for your password!
                </small>
              </div>
              <div className="col-sm-6">
                <label for="passwordTwo">Confirm Password</label>
                <input
                  name="passwordTwo"
                  className="form-control"
                  value={passwordTwo}
                  onChange={this.onChange}
                  type = "password"
                  autoComplete="new-password"
                  placeholder="Confirm Password"
                />
             </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <button 
                    disabled={isInvalid}
                    type="submit"
                    className = "btn btn-primary btn-block"
                    >
                    Register
                    </button>
                    <div className="container text-danger">
                        {error && <p>{error.message}</p>}
                    </div>  
                </div>
            </div>

          </form>
      </div>
    );
  }
}

const SignUpLink = () => (
  <p>
    Don't have an account yet? <Link to={ROUTES.SIGN_UP}>Register</Link>
  </p>
);

const SignUpForm = compose(
    withRouter,
    withFirebase,
  )(SignUpFormBase);

export default SignUpPage;
 
export { SignUpForm, SignUpLink };