import React from 'react';
import { withAuthorization } from '../Session';
import * as ROLES from '../../constants/roles';
import { compose } from 'recompose';

import axios from 'axios';

import {Cart, CartCheck, Check2Circle, Reply} from 'react-bootstrap-icons';

const baseURL = (process.env.NODE_ENV ==='production') ? process.env.REACT_APP_BACKEND_URL_PROD : process.env.REACT_APP_BACKEND_URL_DEV

const axiosSQL = axios.create({
    baseURL : baseURL + '/orders'
})

const Spinner = () => (
    <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
    </div>
)

class OrderPage extends React.Component {
    constructor (props) {
        super (props)
        this.state = {
            orderList : [],
            loading : true,
        }
    }

    async componentDidMount () {
        const statusOrder = ['pending', 'prepared', 'delivered'];
        try {
          const orderList = await axiosSQL.get()
          this.setState({
            orderList : orderList.data,
            loading : false
            })
          this.state.orderList.forEach(order => this.setState({["order#"+order.order_id] : statusOrder.findIndex(obj => obj === order.status)}))
        } catch(error) {
          this.setState({message : JSON.stringify(error)});
        }
      }


    onReturn = async event => {
        const statusOrder = ['pending', 'prepared', 'delivered'];
        const targetId = event.currentTarget.id;
        const currentIndex = this.state["order#"+targetId];
        if (currentIndex > 0) {
            this.setState({["order#"+targetId] : currentIndex - 1})
            const result = await axiosSQL.post(`/status/${statusOrder[currentIndex-1]}/${targetId}`)
            if (result.status === 200) {
                let orderList = this.state.orderList;
                const orderlistIndex = orderList.findIndex(obj => obj.order_id === parseInt(targetId));
                orderList[orderlistIndex].status = statusOrder[currentIndex-1]
                this.setState({orderList : orderList})
            }
        }
    }

    onProgress = async event => { 
        const statusOrder = ['pending', 'prepared', 'delivered'];
        const targetId = event.currentTarget.id;
        const currentIndex = this.state["order#"+targetId];
        if (currentIndex < 2) {
            this.setState({["order#"+targetId] : currentIndex + 1})
            const result = await axiosSQL.post(`/status/${statusOrder[currentIndex+1]}/${targetId}`)
            if (result.status === 200) {
                let orderList = this.state.orderList;
                const orderlistIndex = orderList.findIndex(obj => obj.order_id === parseInt(targetId));
                orderList[orderlistIndex].status = statusOrder[currentIndex+1]
                this.setState({orderList : orderList})
            }
        }

    }

    render () {
        const orderList = this.state.orderList;
        return(
            <div className="container">
                <h1>List of current orders</h1>
                {this.state.loading && <strong style={{"color" : "lightgray"}}>loading <Spinner animation="border" /> </strong>}

                <div className="container overflow-auto">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Order ID</th>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Phone</th>
                                <th scope="col">Content</th>
                                <th scope="col">Delivery Point</th>                                
                                <th scope="col">Status</th>
                                <th scope="col" className="text-center">Update Status</th>
                            </tr>
                        </thead>
                        <tbody>
                                { orderList.map (
                                    order => {
                                        const iconList = [
                                            <Cart id={order.order_id} onClick={this.onProgress} size="25"/>, 
                                            <CartCheck id={order.order_id} onClick={this.onProgress} size="25"/>,
                                            <Check2Circle  id={order.order_id} onClick={this.onProgress} size="25"/>];
                                        return (
                                            <tr key={order.order_id} className={this.state["order#"+order.order_id]===2? "table-success": null}>
                                                <td>{order.order_id}</td>
                                                <td>{order.client_name}</td>
                                                <td>{order.client_email}</td>
                                                <td>{order.client_phone}</td>
                                                <td><pre>{JSON.stringify(JSON.parse(order.order_content), null, 2)}</pre></td>
                                                <td>{order.delivery_point}</td>
                                                <td>{order.status}</td>
                                                <td className="text-center"><Reply 
                                                                                style={{transform: "scaleX(-1)"}}
                                                                                id={order.order_id} 
                                                                                size="25"
                                                                                onClick={this.onReturn}/>{iconList[this.state["order#"+order.order_id]]}</td>
                                            </tr>
                                        )
                                    }
                                )
                                }
                        </tbody>
                    </table>
                </div>
            </div>

        )

    }
}


const condition = authUser => authUser && authUser.roles.includes(ROLES.ADMIN);

export default compose(
  withAuthorization(condition),
)(OrderPage);