import React from 'react';
import { Link } from 'react-router-dom';
import SignOutButton from '../SignOut';
import {Basket3} from 'react-bootstrap-icons';

import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import { connect } from 'react-redux';


const Navigation = ({authUser, cart}) => {
  return (authUser ? (
    <NavigationAuth cart={cart} authUser={authUser}  />
  ) : (
    <NavigationNonAuth />
  )
  )
}

const NavigationAuth = ({ authUser, cart }) => (
  <nav className="navbar navbar-expand-sm navbar-dark fixed-top">
      <Link to={ROUTES.LANDING} className="navbar-brand" data-toggle="collapse" data-target="#navbarNav">Pub LaCloche</Link>
      <div className="collapse navbar-collapse" id="navbarNav" >
        <ul className="navbar-nav bd-navbar-nav mr-auto">
          <li className="nav-item" data-toggle="collapse" data-target="#navbarNav">
            <Link to={ROUTES.SHOPPING} className="nav-link">Shopping</Link>
          </li>
          {authUser.roles.includes(ROLES.ADMIN) && (
            <li className="nav-item" data-toggle="collapse" data-target="#navbarNav">
              <Link to={ROUTES.ADMIN} className="nav-link">Admin</Link>
            </li>
          )}
        </ul>
        <ul className="navbar-nav bd-navbar-nav ml-auto">
          <li className="nav-item dropdown">
            <a className="nav-link" id="navbarDropdown" href="/" role="button" data-toggle="dropdown">
              Welcome {authUser.firstname}
            </a>
            <div className="dropdown-menu" data-toggle="collapse" data-target="#navbarNav">
              <Link to={ROUTES.ACCOUNT} className="dropdown-item ">My Account</Link>   
              <Link to={ROUTES.MY_ORDERS} className="dropdown-item">My Orders</Link>         
              <div className="dropdown-divider"></div>
              <SignOutButton />
            </div>
          </li>
        </ul>
      </div>
      <div className="nav-bar">
        <a className="btn btn-outline-light my-2 my-sm-0" role="button" href="/shopping">
          <Basket3 size="22" fill ="#ffffff"/>
            {cart.total >0 ? <span className="badge badge-success m-1">{cart.total}</span> : null}
        </a>
      </div>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav">
        <span className="navbar-toggler-icon"></span>
      </button>
  </nav>
);

const NavigationNonAuth = () => (
  <nav className="navbar navbar-expand-sm navbar-dark fixed-top">
    <Link to={ROUTES.LANDING} className="navbar-brand" data-toggle="collapse" data-target="#navbarNav">Pub LaCloche</Link>
    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarNav">
      <ul className="navbar-nav bd-navbar-nav mr-auto">
        <li className="nav-item" data-toggle="collapse" data-target="#navbarNav">
          <Link to={ROUTES.SHOPPING} className="nav-link">Shopping</Link>
        </li>
        <li className="nav-item" data-toggle="collapse" data-target="#navbarNav">
          <Link to={ROUTES.SIGN_IN} className="nav-link">Sign In</Link>
        </li>
        <li className="nav-item" data-toggle="collapse" data-target="#navbarNav">
          <Link to={ROUTES.SIGN_UP} className="nav-link">Sign Up</Link>
        </li>
      </ul>
    </div>
  </nav>
);

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  cart: state.cartState
});
 
export default connect(mapStateToProps)(Navigation);
