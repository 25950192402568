import React from 'react';

const LandingPage = () => (
            <div className="container">
                <div className="row">
                    <div className="container">
                        <img src="/images/Banner.jpg" alt="" className="img-fluid"/>
                    </div>
                </div>
                <div className="row">
                    <div className="container">
                        <h1 className="text-center">Pub La Cloche</h1>
                        <p>Le pub La Cloche vous permet de commander sa bière brassée sur place au format keg (30L) et maintenant au format bouteille.</p>
                        <p>Enregistrez-vous et passez votre commande ! </p>
                    </div>
                </div>
            </div>
  )

export default LandingPage;