import React from 'react';

const NonAvailable = () => (
            <div className="container">
                <div className="row">
                    <div className="container">
                        <img src="/images/Banner.jpg" alt="" className="img-fluid"/>
                    </div>
                </div>
                <div className="row">
                    <div className="container">
                        <h1 className="text-center">Page Not Available Yet</h1>
                    </div>
                </div>
            </div>
  )

export default NonAvailable;