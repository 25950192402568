const INITIAL_STATE = {
  cart : {},
  redeem : {},
  total : 0
};
   

function cartReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'CART_SET': {
      return {
        ...state,
        cart : action.cart,
        
      };
    }

    case 'CART_GET': {
      return {
        ...state,
        cart : action.cart,
        cart_id : action.cart_id,
        total : action.total
        };
    }

    case 'CART_UPDATE': {
      return {
        ...state,
        cart : {...action.cart_item, ...state.cart},
        total : Object.values({...action.cart_item, ...state.cart}).reduce((a,b) => a+b,0)
        };
    }

    case 'CART_DELETE': {
      return {
        ...state,
        cart : {},
        total : 0
        };
    }

    case 'CART_DELETE_ITEM': {
      let cleanCart = {}
      Object.keys(state.cart).forEach( key => {
        if (key !== action.cart_item) {
          cleanCart = {...cleanCart, [key] : state.cart[key]}  
        }
      }

      )
      return {
        ...state,
        cart : cleanCart,
        total : Object.values(cleanCart).reduce((a,b) => a+b,0)
      }
    }

    case 'REDEEM_GET': {
      return {
        ...state,
        redeem : action.redeem,
        };
    }

    case 'REDEEM_SET': {
      return {
        ...state,
        redeem : action.redeem,
        
      };
    }

    default:
      return state;
  }
}
   
export default cartReducer;