export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const PASSWORD_RESET = '/password-reset';
export const MANAGE_ORDERS = '/manage-orders';
export const MY_ORDERS = '/my-orders';
export const CHECKOUT = '/checkout';
export const SHOPPING = '/shopping';
export const USERS = '/users';
export const USERS_DETAIL = '/users/:id';
export const REDEEM = '/redeemcodes';
export const NOTAVAILABLE = '/nonavailable';
