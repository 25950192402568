import React from 'react';
import { withAuthorization } from '../Session';
import { compose } from 'recompose';

import axios from 'axios';
import io from 'socket.io-client';


const baseURL = (process.env.NODE_ENV ==='production') ? process.env.REACT_APP_BACKEND_URL_PROD : process.env.REACT_APP_BACKEND_URL_DEV

const axiosSQL = axios.create({
    baseURL : baseURL + '/orders'
})

class myOrderPage extends React.Component {
    constructor (props) {
        super (props)
        this.state = { 
          orderList : [],
          message: {},
          connection :null,
        }
    }

    async componentDidMount () {
        const customerEmail = this.props.authUser.email;
        const statusOrder = ['pending', 'prepared', 'delivered'];
        this.configureSocket();
        try {
          const orderList = await axiosSQL.get('/'+customerEmail)
          this.setState({orderList : orderList.data})
          this.state.orderList.forEach(order => this.setState({["order#"+order.order_id] : statusOrder.findIndex(obj => obj === order.status)}))
        } catch(error) {
          this.setState({message : JSON.stringify(error)});
        }
        console.log('Component mount confirming socket: ', this.socket.id)
        this.socket.on('order_pickup', message => {
            const orderList = this.state.orderList
            console.log('this is the sokect emission', message)
            const orderIndex = orderList.findIndex(order => order.order_id === parseInt(message.orderId))
            if (orderIndex > -1) {
                orderList[orderIndex].status = message.orderStatus
                this.setState({orderList : orderList})
            }
        });
    }

    componentWillUnmount () {
        this.socket.disconnect()
        this.setState({connection : this.socket.connected})
        console.log('disconnected socket in my orders')
    }

    configureSocket = () => {
        var socket = io(baseURL)
        this.socket = socket;
        this.setState({connection : {id: this.socket.id , state: this.socket.connected}})
        console.log('configureSocket connected', this.state.connection)
    }

    render () {
        const orderList = this.state.orderList;
        return(
            <div className="container">
                <h1>List of current orders</h1>
                <div className="container overflow-auto">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Order ID</th>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Phone</th>
                                <th scope="col">Content</th>
                                <th scope="col">Delivery Point</th>                                
                                <th scope="col">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                                { orderList.map (
                                    order => {
                                        let rowFormat = ""
                                        switch (order.status) {
                                            case "delivered" :
                                                rowFormat = "text-muted table-secondary"
                                                break;
                                            case "prepared" :
                                                rowFormat = "table-success"
                                                break;
                                            default:
                                                rowFormat = ""

                                        }
                                        return (
                                            <tr key={order.order_id} className={rowFormat}>
                                                <td>{order.order_id}</td>
                                                <td>{order.client_name}</td>
                                                <td>{order.client_email}</td>
                                                <td></td>
                                                <td><pre>{JSON.stringify(JSON.parse(order.order_content), null, 2)}</pre></td>
                                                <td>{order.delivery_point}</td>
                                                <td>{order.status}</td>
                                            </tr>
                                        )
                                    }
                                )
                                }
                        </tbody>
                    </table>
                </div>
            </div>

        )

    }
}

 
const condition = authUser => !!authUser;

export default compose(
  withAuthorization(condition),
)(myOrderPage);