import React from 'react';
import { withAuthorization } from '../Session';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';

import {mondialMap} from './map'


import axios from 'axios';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { deleteCart, setCode, getCode} from '../../actions/cartaction'


const baseURL = (process.env.NODE_ENV ==='production') ? process.env.REACT_APP_BACKEND_URL_PROD : process.env.REACT_APP_BACKEND_URL_DEV

const axiosSQL_orders = axios.create({
    baseURL : baseURL + '/orders'
})

const axiosSQL_catalog = axios.create({
    baseURL : baseURL + '/catalog'
})

class checkoutPage extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            beerCatalog : [],
            delivery : "lacloche",   
            mr_id : null,
            context : mondialMap,
            redeemAlert : {status: "  "},
            redeemApproved : false,
         }    
    }

    componentDidMount () {
        this.state.context.mondialMap()
        this.props.getCode(this.props.authUser.uid)
        // get beer catalog
        axiosSQL_catalog
            .get('/beers')
            .then(beerCatalog => {
            this.setState({
                beerCatalog : beerCatalog.data,
                })  
            })
            .catch(error => {this.setState({message : JSON.stringify(error)})})

    }

    onSubmit = event => {
        event.preventDefault();
        const orderRecap = {
            date : new Date().toLocaleString('sv'),
            name : this.props.authUser.displayName || 'unkown',
            email : this.props.authUser.email,
            phone : this.props.authUser.phoneNumber || null,
            delivery: this.state.delivery,
            status : 'pending',
            order : this.props.cart,
            mr_id : this.state.mr_id,
            redeemId : this.props.redeem.id || null,
            redeemCode : this.props.redeem.name  || null,
            total : (1-this.props.redeem.amount/100)*this.props.total*4 || this.props.total*4,
        }
        axiosSQL_orders
            .post('/addorder', orderRecap)
            .then( res => {
                this.props.deleteCart(this.props.authUser.uid);
                this.props.history.push(ROUTES.MY_ORDERS)
            })
            .catch(err => {console.log('error while submitting cart', err)})
    }

    onSubmitRedeem = async event => {
        event.preventDefault();
        try {
            const redeem = await axiosSQL_orders.get('/redeem/'+ this.state.redeemCode)
            // check if redeem code exist
            if (Array.isArray(redeem.data) && redeem.data.length >0) {
                // if redeem exist filter the first one
                const redeemContent = redeem.data[0]
                // register to redux store (and hence redis)
                this.props.setCode(redeemContent, this.props.authUser.uid)
                // update the state to display the alter accordingly
                this.setState({
                    redeemApproved : true,
                    redeemAlert : {
                        message: 'The code was applied',
                        type : " alert-success ",
                        status: " show "
                    }
                })
                // display the redux store
            } else {
                this.setState({ redeemAlert : {
                    message: 'This code is not valid or expired',
                    type : " alert-danger ",
                    status: " show "
                }}); 
            }} catch (err) {
                this.setState({ redeemAlert : {
                    message: 'There was an error reading the code',
                    type : " alert-warning ",
                    status: " show "
                }}); 
                console.log('error while submitting redeem code', err)
            }

    }

    onChange = event => {
        this.setState({delivery: event.target.value});
      }
    
    onChangeShipping = event => {
        this.setState({mr_id: document.getElementById("Target_Widget").value})
    }
    
    onChangeRedeem = event => {
        this.setState({redeemCode : event.target.value})
    }

    render () {
        var shoppingCart = []
        Object.keys(this.props.cart).map(
            item => {
                return shoppingCart.push({
                            name : item,
                            quantity: this.props.cart[item]
                        })
            }
        )
        const isRedeemApproved = this.props.redeem && this.props.redeem.id > 0
        return(
            <div className="container">
                <h1>Ready to order?</h1>
                <span>1. Review, select delivery point and confirm</span>
                <hr/>
                <div className="col-md-6 justify-content-center">
                    <div className="">
                        <ul className="list-group mb-3">
                            {
                                shoppingCart.map(
                                    order => {
                                        return(
                                            <li key={order.name} className="list-group-item d-flex justify-content-between lh-condensed">
                                                <div>
                                                    <h6 className="my-0">{order.name}
                                                    </h6>
                                                </div>
                                                <span className="text-muted">
                                                    {order.quantity}
                                                </span>
                                            </li>
                                        )
                                    }
                                )   
                            }
                            {(isRedeemApproved) ? 
                                                        <li className="list-group-item d-flex justify-content-between lh-condensed bg-light">
                                                        <div className="text-success">
                                                            <h6>
                                                                Code Reduc
                                                            </h6>
                                                            <small>
                                                                {this.props.redeem.description}
                                                            </small>                                                        
                                                        </div>
                                                        <span className="text-success">
                                                            {this.props.redeem.amount+"%"}
                                                        </span>
                                                    </li>
                                    :
                                    null                        
                            }
                            <li className="list-group-item d-flex justify-content-between lh-condensed">
                                <div className="text-secondary">
                                    <h6 className="my-0">
                                        Total
                                    </h6>
                                </div>
                                <div>
                                    <h6 className="my-0 text-right text-secondary">
                                        {shoppingCart.length > 0 ? this.props.total : null}
                                    </h6>
                                    <small>{isRedeemApproved
                                    ? <del>{shoppingCart.length > 0 ? "€"+this.props.total*4 : null}</del>
                                    : <p>{shoppingCart.length > 0 ? "€"+this.props.total*4 : null}</p>                                        
                                    }
                                    </small>
                                    <small>{isRedeemApproved
                                    ? <strong className="text-success">{shoppingCart.length > 0 ? "> €"+(+(Math.round(this.props.total*4*(1-this.props.redeem.amount/100)+"e+2")+"e-2")) : null}</strong>
                                    : null
                                    }
                                    </small>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="">
                        <form action="" className="card p-2"  onSubmit={this.onSubmitRedeem}>
                            <div className="input-group">
                                <input 
                                    type="text"
                                    className="form-control"
                                    placeholder= {this.props.redeem.name || "code reduc"}
                                    onChange = {this.onChangeRedeem}
                                />
                                <div className="input-group-append">
                                    <button type="submit" className="btn btn-secondary">
                                        Appliquer
                                    </button>

                                </div>
                            </div>
                        </form>
                        <div className={"alert alert-dismissible fade" + this.state.redeemAlert.status + this.state.redeemAlert.type} role="alert">
                            {this.state.redeemAlert.message}
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                </div> 
                <hr/>
                <p>2. Select delivery point</p>
                <form className="from-inline" onSubmit={this.onSubmit}>
                    <div className="form-check form-check-inline" onChange={this.onChange}>
                        <input 
                            className="form-check-input mb-2" 
                            type="radio" 
                            name="delivery" 
                            id="delivery1" 
                            value="lacloche"
                            defaultChecked
                            // checked = {(this.state.delivery === "lacloche")}
                            />
                        <label className="form-check-label mr-3 mb-2" htmlFor="delivery1">
                            Au pub La Cloche à Bors mon gars!
                        </label>
                        <input 
                            className="form-check-input mb-2"
                            type="radio"
                            name="delivery"
                            id="delivery2"
                            value="bertrand"
                            // checked = {(this.state.delivery === "bertrand")}
                            />
                        <label className="form-check-label mr-3 mb-2" htmlFor="delivery2">
                            Chez Bertrand directement mec!
                        </label>
                        <input
                            className="form-check-input mb-2"
                            type="radio"
                            name="delivery"
                            id="delivery3"
                            value="mondial"
                            // checked = {(this.state.delivery === "mondial")}
                            />
                        <label className="form-check-label mb-2" htmlFor="delivery3">
                            Par Mondial relay !
                        </label>
                    </div>
                    <div className="row" style={{display : (this.state.delivery ==='mondial')? "block" : "none"}}>
                        <div className="col-md-9 d-flex justify-content-center" id="Zone_Widget" onClick={this.onChangeShipping}>

                        </div>
                        <input className="invisible" type="text" id="Target_Widget" />
                    </ div>
                    <div className="row">
                        <button type="submit" className="btn col-md-11 btn-lg btn-primary mt-3">Confirm</button>
                        <Link to={ROUTES.SHOPPING} className="btn  col-md-1 btn-lg btn-outline-secondary mt-3" >Back</Link>
                    </div>

                </form>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return ({
      cart: state.cartState.cart,
      total : state.cartState.total,
      redeem : state.cartState.redeem
    })};
  
const condition = authUser => !!authUser;


export default compose(
  withAuthorization(condition),
  connect(
    mapStateToProps,
    {deleteCart, setCode, getCode}
  ),
)(checkoutPage);