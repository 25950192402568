import { applySetUsers, applySetUser} from '../actions/useractions'

const INITIAL_STATE = {
    users: null,
  };
   
   
function userReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'USERS_SET': {
      return applySetUsers(state, action);
    }
    case 'USER_SET': {
      return applySetUser(state, action);
    }
    default:
      return state;
  }
}
   
  export default userReducer;