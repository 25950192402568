export const onSetUsers = (users) => dispatch => {
    dispatch({ type: 'USERS_SET', users });
}

export const applySetUsers = (state, action) => ({
...state,
users: action.users,
});

export const applySetUser = (state, action) => ({
...state,
users: {
    ...state.users,
    [action.uid]: action.user,
},
});

