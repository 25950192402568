import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { PasswordResetForm } from '../PasswordReset';
import PasswordChangeForm from '../PasswordChange';
import { withAuthorization } from '../Session';

const AccountPage = ({authUser}) => (
    <div className="container">
        <h1>Account : {authUser.email} </h1>
                <PasswordResetForm />
                <PasswordChangeForm />
    </div>
);

const mapStateToProps = state => ({
    authUser: state.sessionState.authUser,
  });

const condition = authUser => !!authUser;

export default compose(
    connect(mapStateToProps),
    withAuthorization(condition),
  )(AccountPage);