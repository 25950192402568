import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {EmojiDizzy} from 'react-bootstrap-icons';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const PasswordResetPage = ({authUser}) => (
  <div className="container">
    <PasswordResetForm />
  </div>
);

const INITIAL_STATE = {
  email: '',
  error: null,
};

class PasswordResetFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }
 
  onSubmit = event => {
    const { email } = this.state;
 
    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });
 
    event.preventDefault();
  };
 
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
 
  render() {
    const { email, error } = this.state;
 
    const isInvalid = email === '';
 
    return (
      <div className="container">
        <div className="container text-center">
          <EmojiDizzy size="55" alt="" fill ="#a32222"/>
          <h1 className="h2 font-weight-normal">Reset Password</h1>
        </div>
        <form className="form-signin" onSubmit={this.onSubmit}>
          <input
            name="email"
            className = "form-control"
            value={this.state.email}
            onChange={this.onChange}
            type="text"
            placeholder="Email Address"
            autoComplete = "email"
          />
            <button 
              disabled={isInvalid}
              type="submit"
              className = "btn btn-lng btn-primary btn-block"
              >
              Reset Password
            </button>
            <div className="container text-danger">
               {error && <p>{error.message}</p>}
            </div>
        </form>
      </div>
    );
  }
}
 
const PasswordResetLink = () => (
  <p>
    <Link to={ROUTES.PASSWORD_RESET}>Forgot Password?</Link>
  </p>
);
 
export default PasswordResetPage;
 
const PasswordResetForm = withFirebase(PasswordResetFormBase);
 
export { PasswordResetForm, PasswordResetLink };