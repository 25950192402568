import React from 'react';
import { compose } from 'recompose';

import {Shop, GraphUp, People, Receipt, Percent} from 'react-bootstrap-icons';

import { withAuthorization } from '../Session';
import * as ROLES from '../../constants/roles';
import * as ROUTES from '../../constants/routes';

const AdminPage = () => (
  <div className="container text-center ">
    <div className="row justify-content-center">
      <div className="card border-secondary shadow-lg m-2" style={{width: "18rem"}}>
        <Shop className="card-img-top mx-auto align-middle mt-2" size="100"/>
        <div className="card-body">
          <h5 className="card-title">Update Beer Catalog</h5>
          <p className="card-text">Use this to update the beer catalog with name, picture, description. You can also add seasonal beer</p>
          <a href={ROUTES.NOTAVAILABLE} className="btn btn-light"><strong>Go</strong></a>
        </div>
      </div>
      <div className="card border-secondary shadow-lg m-2" style={{width: "18rem"}}>
        <Receipt className="card-img-top mx-auto align-middle mt-2" size="100"/>
        <div className="card-body">
          <h5 className="card-title">Update Open Orders</h5>
          <p className="card-text">Use this to mark that beer is available at La Cloche for reception or that an order was picked up. This should also manage Mondial Relay</p>
          <a href={ROUTES.MANAGE_ORDERS} className="btn btn-light"><strong>Go</strong></a>
        </div>
      </div>
      <div className="card border-secondary shadow-lg m-2" style={{width: "18rem"}}>
        <GraphUp className="card-img-top mx-auto align-middle mt-2" size="100"/>
        <div className="card-body">
          <h5 className="card-title">Forecast Production</h5>
          <p className="card-text">This summarizes the beer required for production and in the near future it should also show foreacst for future months</p>
          <a href={ROUTES.NOTAVAILABLE} className="btn btn-light"><strong>Go</strong></a>
        </div>
      </div>
      <div className="card border-secondary shadow-lg m-2" style={{width: "18rem"}}>
        <People className="card-img-top mx-auto align-middle mt-2" size="100"/>
        <div className="card-body">
          <h5 className="card-title">Manage users</h5>
          <p className="card-text">Add roles to users (admin, etc...) and manage if they can pick up beer from Bertrand or other points</p>
          <a href={ROUTES.USERS} className="btn btn-light"><strong>Go</strong></a>
        </div>
      </div>
      <div className="card border-secondary shadow-lg m-2" style={{width: "18rem"}}>
        <Percent className="card-img-top mx-auto align-middle mt-2" size="100"/>
        <div className="card-body">
          <h5 className="card-title">Redeem Codes</h5>
          <p className="card-text">Manage Discount codes</p>
          <a href={ROUTES.REDEEM} className="btn btn-light"><strong>Go</strong></a>
        </div>
      </div>
    </div>
  </div>
);

const condition = authUser =>
  authUser && authUser.roles.includes(ROLES.ADMIN);

export default compose(
  withAuthorization(condition),
)(AdminPage);