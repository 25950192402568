import React from 'react';
import { withAuthorization } from '../Session';
import * as ROUTES from '../../constants/routes';
import {Plus, Dash, ArrowRepeat} from 'react-bootstrap-icons';

import { initCart, updateCart, deletecartItem, setCart, deleteCart} from '../../actions/cartaction'
import { connect } from 'react-redux';
import { compose } from 'recompose';

import axios from 'axios';

const baseURL = (process.env.NODE_ENV ==='production') ? process.env.REACT_APP_BACKEND_URL_PROD : process.env.REACT_APP_BACKEND_URL_DEV

const axiosSQL = axios.create({
    baseURL : baseURL + '/catalog'
})


class ShoppingPage extends React.Component{
    constructor (props) {
      super(props);
      this.state = {
        beerList : [],
      }
    }
  
    componentDidMount () {
      //instantiate state cart in store  
      this.props.initCart(this.props.authUser.uid)
      // get beer catalog
      axiosSQL
        .get('/beers')
        .then(beerList => {
          this.setState({
            beerList : beerList.data,
            })  
        })
        .catch(error => {this.setState({message : JSON.stringify(error)})})
    }

    onSubmit = event => {
      event.preventDefault();
      // update store
      this.props.setCart(this.props.cart, this.props.authUser.uid)
        .then(this.props.history.push(ROUTES.CHECKOUT));
    };

    onIncrementItem = event => {
      let cartTemp = this.props.cart;
      if (cartTemp[event.currentTarget.value]) {
        cartTemp[event.currentTarget.value] +=  1;
      } else {
        cartTemp = {[event.currentTarget.value] :  1};
      }
      this.props.updateCart({[event.currentTarget.value] : cartTemp[event.currentTarget.value]})
    };
  
    onDecreaseItem = event => {
      let cartTemp = this.props.cart;
      if (cartTemp[event.currentTarget.value]> 1) {
        cartTemp[event.currentTarget.value] -=  1;
        this.props.updateCart({[event.currentTarget.value] : cartTemp[event.currentTarget.value] })
      }
      else if (cartTemp[event.currentTarget.value] === 1) {
        this.props.deletecartItem(event.currentTarget.value)
      }
    };

    onReset = () => {
      this.props.deleteCart(this.props.authUser.uid)
    }

    render() {
      const beerList = this.state.beerList;
      function checkJSON(obj) {
          for(var i in obj) return false; 
          return true;
      }

      return (
        <div className="container">
          <h1 className="text-center">Les bières de la brasserie La Cloche</h1>
              <button 
                onClick={this.onSubmit}
                className={!checkJSON(this.props.cart)? "col-md-11 btn btn-lg btn-success mb-3" : "col-md-11 btn btn-lg btn-secondary mb-3"}
                disabled= {checkJSON(this.props.cart) ? "disabled" : null}
              >
                Proceed to checkout
              </button>
              <button 
                onClick={this.onReset}
                className="col-md-1 btn btn-lg btn-outline-secondary mb-3"
              >
                <ArrowRepeat />      
              </button>
          <div className="card-columns">
            {
             beerList.map(
               beer => {
                let beerRequired =  this.props.cart[beer.name] || 0;
                return (
                    <div className="card bg-light" key={"card"+beer.beer_id}>
                      <div className="row no-gutters">
                         <div className="col-md-4 text-center align-self-center">
                           <img src={beer.src} className="card-img" alt="test" />
                           <p className="text-center small">{beer.degree}</p>
                         </div>
                         <div className="col-md-8">
                           <div className="card-body">
                             <div className="row mb-2">
                               <h5 className="card-title">{beer.name}</h5>
                               <p className="card-text small">{beer.description}</p>
                             </div>
                             <div className="row">
                               <div className="input-group input-group-sm mb-2 justify-content-center">
                                 <div className="input-group-prepend">
                                   <button className="btn btn-sm btn-outline-secondary" type="button" value={beer.name} onClick={this.onIncrementItem}><Plus /></button>
                                   <button className="btn btn-sm btn-outline-secondary" type="button"  value={beer.name} onClick={this.onDecreaseItem}><Dash /></button>
                                 </div>
                                 <input 
                                    type="text"
                                    className={(beerRequired >0)? "form-control cart-input text-center is-cart":"form-control disabled cart-input text-center"}
                                    readOnly={true} 
                                    id={beer.name} 
                                    placeholder="" 
                                    value={beerRequired}
                                  />
                               </div>
                             </div>
                           </div>
                         </div>
   
                     </div> 
                    </div>
                )
               } 
             ) 
            }
          </div>
        </div>

        )
    } 
}

const mapStateToProps = state => {
  return ({
    cart: state.cartState.cart
  })};

const condition = authUser => !!authUser;

export default compose(
  // withAuthentication(condition),
  withAuthorization(condition), 
  connect(
    mapStateToProps,
    {initCart, updateCart, setCart, deletecartItem, deleteCart},
  ),
)(ShoppingPage);
