import axios from 'axios';

const baseURL = (process.env.NODE_ENV ==='production') ? process.env.REACT_APP_BACKEND_URL_PROD : process.env.REACT_APP_BACKEND_URL_DEV

const axiosRedis = axios.create({
    baseURL : baseURL + '/cart'
})

export const initCart = id => dispatch => {
    axiosRedis
        .get(`/list/${id}`)
        .then( res => {
            dispatch({
                type : 'CART_GET',
                cart_id : id,
                cart : res.data,
                total : Object.values(res.data).reduce((a,b) => a+b,0)
            })
        })
        .catch(error => console.log('could not get cart from Redis', error))
    }

export const setCart = (cart, id) => async dispatch => {
    deleteCart(id);
    axiosRedis
        .post(`/list/${id}`, cart)
        .then(res => {
            dispatch({
                type : 'CART_SET',
                cart : cart,
            })
        })
        .catch(error => console.log('could not update cart to Redis', error))
}

export const updateCart = (cart) => dispatch => {
        dispatch({
            type : 'CART_UPDATE',
            cart_item : cart,
    })
}

export const deleteCart = (id) => dispatch => {
    axiosRedis
    .delete(`/delete/${id}`)
    .then(res => {
        dispatch({
            type : 'CART_DELETE'
        })
    })
    .catch(error => console.log('could not update cart to Redis', error))

}

export const deletecartItem = (item) => dispatch => {
    dispatch({
        type : 'CART_DELETE_ITEM',
        cart_item : item
    })
}

export const setCode = (redeem, id) => dispatch => {
    axiosRedis
        .post(`/redeem/${id}`, redeem)
        .then( res => {
            dispatch({
                type : 'REDEEM_SET',
                redeem : redeem
            })
        })
        .catch(error => console.log('could not get code from Redis', error))
    }

    export const getCode = id => async dispatch => {
        axiosRedis
            .get(`/redeem/${id}`)
            .then( res => {
                dispatch({
                    type : 'REDEEM_GET',
                    redeem : res.data,
                })
            })
            .catch(error => console.log('could not get code from Redis', error))
        }
    
