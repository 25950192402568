import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { onSetUsers} from '../../actions/useractions'

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const Spinner = () => (
  <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
  </div>
)

class UserList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    if (!this.props.users.length) {
      this.setState({ loading: true });
    }
    this.props.firebase.users().on('value', snapshot => {
      this.props.onSetUsers(snapshot.val());
      this.setState({ loading: false });
    }, errorObject => {
      console.log("The read failed: " + errorObject.code)
    } );
  }

  componentWillUnmount() {
    this.props.firebase.users().off();
  }

  render() {
    const { users } = this.props;
    const { loading } = this.state;
    return (
      <div className="container">
        <h2>Users</h2>
        {loading && <strong style={{"color" : "lightgray"}}>loading <Spinner animation="border" /> </strong>}
        <div className="container overflow-auto">
          <table className="table">
            <thead>
                <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Roles</th>
                    <th scope="col" className="text-center">Actions</th>
                </tr>
            </thead>
            <tbody>
              { users.map (
                  user => {
                      return (
                          <tr key={user.uid}>
                            <td>{user.uid}</td>
                            <td>{user.displayName}</td>
                            <td>{user.email}</td>
                            <td>{user.phoneNumber}</td>
                            <td>{JSON.stringify(user.roles)}</td>
                            <td className="text-center">
                              <Link to={`${ROUTES.USERS}/${user.uid}`}>Details</Link> | <Link to={`${ROUTES.USERS}/${user.uid}`}>Update Role</Link>
                            </td>
                          </tr>
                      )
                    }
                )
              }
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  users: Object.keys(state.userState.users || {}).map(key => ({
    ...state.userState.users[key],
    uid: key,
  })),
});


export default compose(
  withFirebase,
  connect(
    mapStateToProps,
    { onSetUsers }
  ),
)(UserList);