import React, { Component } from 'react';
import {Shop} from 'react-bootstrap-icons';
import { withRouter } from 'react-router-dom';
import { SignUpLink } from '../SignUp';
import { PasswordResetLink } from '../PasswordReset';
import { withFirebase } from '../Firebase';
import { compose } from 'recompose';

import * as ROUTES from '../../constants/routes';

const SignInPage = () => (
  <div className="container text-center">
    <SignInForm />
    <PasswordResetLink />
    <hr/>
    <p>Or use your social account</p>
    <SignInGoogle />
    <hr/>
    <SignUpLink />
  </div>
);
 
const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};
 
class SignInFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }
 
  onSubmit = event => {
    const { email, password } = this.state;
 
    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.SHOPPING);
      })
      .catch(error => {
        this.setState({ error });
      });
 
    event.preventDefault();
  };
 
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
 
  render() {
    const { email, password, error } = this.state;
 
    const isInvalid = password === '' || email === '';
 
    return (
      <div className="container text-center">
        <form className="form-signin" onSubmit={this.onSubmit}>
          <Shop size="55" alt="" fill ="#a32222"/>
          <h1 className="h3 mb-3 font-weight-normal text-center">Login</h1>
          <input
            className="form-control"
            name="email"
            value={email}
            onChange={this.onChange}
            type="text"
            autoComplete = "username"
            placeholder="Email Address"
            required
            autofocus
          />
          <input
            className = "form-control"
            name="password"
            value={password}
            onChange={this.onChange}
            type = "password"
            autoComplete="current-password"
            placeholder="Password"
            required
          />
          <button 
            disabled={isInvalid}
            type="submit"
            className = "btn btn-lng btn-primary btn-block"
            >
            Sign In
          </button>
          {error && <p>{error.message}</p>}
        </form>
      </div>
    );
  }
}

class SignInGoogleBase extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  onSubmit = event => {
    this.props.firebase
      .doSignInWithGoogle()
      .then(socialAuthUser => {
        // Create a user in your Firebase Realtime Database too
        if (socialAuthUser.additionalUserInfo.isNewUser) {
          return this.props.firebase
            .user(socialAuthUser.user.uid)
            .set({
              firstname: socialAuthUser.user.displayName,
              lastname : '',
              email: socialAuthUser.user.email,
              displayName : socialAuthUser.user.displayName,
              phoneNumber : socialAuthUser.user.phoneNumber || '',
              roles : '[]'
            });
        }
      })
      .then(() => {
        this.setState({ error: null });
        this.props.history.push(ROUTES.SHOPPING);
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    return (
        <img src="/google.svg" className="img-social" height="42" width="42" alt="Google" onClick={this.onSubmit} />
    );
  }
}

const SignInForm = compose(
  withRouter,
  withFirebase,
)(SignInFormBase);

const SignInGoogle = compose(
  withRouter,
  withFirebase,
)(SignInGoogleBase);


export default SignInPage;
 
export {SignInForm, SignInGoogle};